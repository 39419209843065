import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const SocialMediaHandle = createApi({
  reducerPath: "socialMediaHandle",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["socialMediaHandle"], // automatic-data fetching
  endpoints: (builder) => ({
    GetAllSocialMediaHandles: builder.query({
        query: () => `/common/get-all-social-media-handle`,
        providesTags: ["socialMediaHandle"],
      }),
  }),
});

export const {
    useGetAllSocialMediaHandlesQuery
} = SocialMediaHandle;