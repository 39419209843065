import { createSlice } from "@reduxjs/toolkit";

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("smTaskSubTask");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const initialState = loadState() || {
  task: null,
  subTask: null,
};

export const smTaskSubTaskSlice = createSlice({
  name: "smTaskSubTask",
  initialState,
  reducers: {
    setTask: (state, action) => {
      state.task = action.payload.task;
      // Save to localStorage whenever state changes
      localStorage.setItem("smTaskSubTask", JSON.stringify(state));
    },
    setSubTask: (state, action) => {
      state.subTask = action.payload.subTask;
      // Save to localStorage whenever state changes
      localStorage.setItem("smTaskSubTask", JSON.stringify(state));
    },
    resetTaskSubTask: (state) => {
      state.task = null;
      state.subTask = null;
      // Clear localStorage when resetting
      localStorage.removeItem("smTaskSubTask");
    },
  },
});

export const { setTask, setSubTask, resetTaskSubTask } =
  smTaskSubTaskSlice.actions;

export default smTaskSubTaskSlice.reducer;
