import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const leaderBoardApi = createApi({
  reducerPath: "leaderBoardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["leaderBoard"],
  endpoints: (builder) => ({
    dailyLeaderBoard: builder.query({
      query: () => "/secure/daily_leaderboard_live_data",
      providesTags: ["leaderBoard"],
    }),
    weeklyLeaderBoard: builder.query({
      query: () => "/secure/weekly_leaderboard_live_data",
      providesTags: ["leaderBoard"],
    }),
    monthlyLeaderBoard: builder.query({
      query: () => "/secure/monthly_leaderboard_live_data",
      providesTags: ["leaderBoard"],
    }),
    allLeaderBoardHistory: builder.query({
      query: () => "/secure/all_leaderboard_history",
      providesTags: ["leaderBoard"],
    }),
    claimNow: builder.mutation({
      query: (body) => ({
        url: "/secure/claim-now",
        method: "POST",
        body,
      }),
      invalidatesTags: ["leaderBoard"],
    }),
    getAllMysteryBox: builder.query({
      query: () => "/secure/get-all-mistbow",
      providesTags: ["leaderBoard"],
    }),
    claimBtn: builder.query({
      query: () => "/secure/claim-button",
      providesTags: ["leaderBoard"],
    }),
    getLeaderBoardData: builder.query({
      query: ({ page, limit }) =>
        `/secure/get_leader_board_data?page=${page}&limit=${limit}`,
      providesTags: ["leaderBoard"],
    }),
    getMyPointHistory: builder.query({
      query: ({ page, limit, type }) =>
        `/secure/get_user_all_point_history?page=${page}&limit=${limit}&type=${type}`,
      providesTags: ["leaderBoard"],
    }),
    getTotalPoints: builder.query({
      query: () => "/secure/get_total_points",
      providesTags: ["leaderBoard"],
    }),
    getLeaderBoardUserInfo: builder.query({
      query: (username) =>
        `/secure/get-leader-board-user-info?username=${username}`,
      providesTags: ["leaderBoard"],
    }),
    getAllPurposeIcons: builder.query({
      query: () => '/secure/getUserPurposeIcon',
      transformResponse: (response) => response.data, 
    }),
  }),
});

export const {
  useDailyLeaderBoardQuery,
  useWeeklyLeaderBoardQuery,
  useMonthlyLeaderBoardQuery,
  useAllLeaderBoardHistoryQuery,
  useClaimNowMutation,
  useGetAllMysteryBoxQuery,
  useClaimBtnQuery,
  useGetLeaderBoardDataQuery,
  useGetMyPointHistoryQuery,
  useGetTotalPointsQuery,
  useGetLeaderBoardUserInfoQuery,
  useGetAllPurposeIconsQuery
} = leaderBoardApi;
