import React, { useEffect, useState } from "react";
import Header from "../../../components/Dashboard/Header";
import BottomMenu from "../../../components/Dashboard/BottomMenu";
import { Notification } from "../../../components/ToastNotification";
import { FiHelpCircle } from "react-icons/fi";
import { LuMessageCircle, LuMail } from "react-icons/lu";
import { IoCallOutline } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useMyProfileQuery } from "../../../services/userApi";

const HelpAndSupport = () => {
  const navigate = useNavigate();

  const { data } = useMyProfileQuery();
  return (
    <div className="tpu__dashboard__wrapper layoutContainer">
      <div
        className="layoutContainer"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header screenName="Help & Support" />
        <div className="help-support-content">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://triplewsols.tawk.help/", "_blank")
            }
            className="help-support-item"
          >
            <span className="help-support-iconContainer">
              <FiHelpCircle />
            </span>
            <div className="help-support-textContainer">
              <div className="help-support-title">Help</div>
              <div className="help-support-subtitle">Popular FAQs</div>
            </div>
            <div className="help-support-arrow">
              <MdKeyboardArrowRight />
            </div>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://tawk.to/taskplanet", "_blank")}
            className="help-support-item"
          >
            <span className="help-support-iconContainer">
              <LuMessageCircle />
            </span>
            <div className="help-support-textContainer">
              <div className="help-support-title">Chat with Us</div>
              <div className="help-support-subtitle">Instant Support</div>
            </div>
            <div className="help-support-arrow">
              <MdKeyboardArrowRight />
            </div>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => window.open("tel:+919826000000", "_blank")}
            className="help-support-item"
          >
            <span className="help-support-iconContainer">
              <IoCallOutline />
            </span>
            <div className="help-support-textContainer">
              <div className="help-support-title">Phone Support</div>
              <div className="help-support-subtitle">(9 AM - 6 PM)</div>
            </div>
            <div className="help-support-arrow">
              <MdKeyboardArrowRight />
            </div>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                `https://wa.me/918062181206?text=Hi%20my%20username%20is%20${data?.data[0]?.username}%20and%20I%20need%20help`,
                "_blank"
              )
            }
            className="help-support-item"
          >
            <span className="help-support-iconContainer">
              <LuMessageCircle />
            </span>
            <div className="help-support-textContainer">
              <div className="help-support-title">WhatsApp Support</div>
              <div className="help-support-subtitle">
                Fast Reply in 5 Minutes (Indian Users Only)
              </div>
            </div>
            <div className="help-support-arrow">
              <MdKeyboardArrowRight />
            </div>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                `https://wa.me/916307182501?text=Hi%20my%20username%20is%20${data?.data[0]?.username}%20and%20I%20need%20help`,
                "_blank"
              )
            }
            className="help-support-item"
          >
            <span className="help-support-iconContainer">
              <LuMessageCircle />
            </span>
            <div className="help-support-textContainer">
              <div className="help-support-title">WhatsApp Support</div>
              <div className="help-support-subtitle">
                Fast Reply in 5 Minutes (International Users Only)
              </div>
            </div>
            <div className="help-support-arrow">
              <MdKeyboardArrowRight />
            </div>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => window.open("mailto:support@taskplanet.org", "_blank")}
            className="help-support-item"
          >
            <span className="help-support-iconContainer">
              <LuMail />
            </span>
            <div className="help-support-textContainer">
              <div className="help-support-title">Email Support</div>
              <div className="help-support-subtitle">
                Response within 24 Hours
              </div>
            </div>
            <div className="help-support-arrow">
              <MdKeyboardArrowRight />
            </div>
          </div>
        </div>
        <div className="bottom-menu-wrapper">
          <BottomMenu />
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
