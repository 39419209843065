import React from "react";
import { Navigate, Route, Routes, useNavigate, useNavigation } from "react-router-dom";
import SplashScreen from "../screens/SplashScreen";
import AuthSplashScreen from "../screens/SplashScreen/AuthSplashScreen";
import MobileLoginScreen from "../screens/AuthPage/MobileLogin";
import EmailLoginScreen from "../screens/AuthPage/EmailLogin";
import MobileForgotPasswordScreen from "../screens/AuthPage/MobileForgotPassword";
import EmailForgotPasswordScreen from "../screens/AuthPage/EmailForgotPassword";
import Dashboard from "./dashboard";
import MobileResetPasswordScreen from "../screens/AuthPage/ResetMobilePassword";
import EmailResetPasswordScreen from "../screens/AuthPage/ResetEmailPassword";
import GoogleAdditionalInfo from "../screens/AuthPage/GoogleAdditionalInfo";
import EmailAdditionalInfo from "../screens/AuthPage/EmailAdditionalInfo";
import MobileAdditionalInfo from "../screens/AuthPage/MobileAdditionalInfo";
import TermsAndCondition from "../screens/TermsAndCondition/TermsAndCondition";
import Privacypolicy from "../screens/Privacypolicy/Privacypolicy";
import Careers from "../screens/Career/Career";
// import AboutUs from "../screens/AboutUs/AboutUs";
import PaymentMethod from "../screens/PaymentMethod/PaymentMethod";
import RefundPolicy from "../screens/RefundPolicy/RefundPolicy";
import ContactUs from "../screens/ContactUs/ContactUs";
import { getLocalStorage } from "../utils/function/localStorage";

const Routers = () => {

  const token = getLocalStorage("tp_Aa_uth_access");
  return (
    <>
      <Routes>
        <Route
          path="/*"
          index
          element={token ? <Navigate to="/home" /> : <SplashScreen />}
        />
        <Route path="/home/*" element={<Dashboard />} />
        <Route path="/authSplashScreen" element={<AuthSplashScreen />} />
        <Route path="/loginWithMobile" element={<MobileLoginScreen />} />
        <Route path="/loginWithEmail" element={<EmailLoginScreen />} />
        <Route
          path="/mobileResetPassword"
          element={<MobileResetPasswordScreen />}
        />
        <Route
          path="/mobileForgotPassword"
          element={<MobileForgotPasswordScreen />}
        />
        <Route
          path="/emailForgotPassword"
          element={<EmailForgotPasswordScreen />}
        />
        <Route
          path="/emailResetPassword"
          element={<EmailResetPasswordScreen />}
        />
        <Route
          path="/googleAdditionalInfo"
          element={<GoogleAdditionalInfo />}
        />
        <Route path="/emailAdditionalInfo" element={<EmailAdditionalInfo />} />
        <Route
          path="/mobileAdditionalInfo"
          element={<MobileAdditionalInfo />}
        />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        <Route path="/payment-method" element={<PaymentMethod />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </>
  );
};

export default Routers;
