import React from "react";
import {
  useMyProfileQuery,
  useGetManageReferralsQuery,
} from "../../../services/userApi";
// Import icons from react-icons
import { FiUser, FiLogOut, FiRocket } from "react-icons/fi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { SlInfo } from "react-icons/sl";
import { removeLocalStorage } from "../../../utils/function/localStorage";
import "./HeaderDropdown.css";
import { useNavigate } from "react-router-dom";
import { IoRocketOutline } from "react-icons/io5";
import pointsIcon from "../../../assets/images/Point.png";

const HeaderDropDown = ({ visible, setVisible }) => {
  const { data } = useMyProfileQuery();
  const navigate = useNavigate();
  const handleLogout = () => {
    removeLocalStorage("tp_Aa_uth_access");
    removeLocalStorage("userCountry")
    window.location.reload();
  };

  const { data: manageReferralsData, refetch: refetchManageReferrals } =
    useGetManageReferralsQuery();

  const calculateAvailablePoints = () => {
    if (!data?.data?.[0]) return 0;
    const user = data.data[0];
    // console.log(user);

    let points = 0;
    if (!user.isEmailVerified)
      points += manageReferralsData?.data?.profile_email_update;
    if (
      (user.country === "India" ||
        user.country === "Pakistan" ||
        user.country === undefined) &&
      !user.isMobileVerified
    )
      points += manageReferralsData?.data?.profile_mobile_update;
    if (!user.dob) points += manageReferralsData?.data?.profile_dob_update;
    if (!user.gender)
      points += manageReferralsData?.data?.profile_gender_update;
    if (!user.address)
      points += manageReferralsData?.data?.profile_address_update;
    if (!user.profile_image_status)
      points += manageReferralsData?.data?.profile_avatar_update;
    if (!user.isKYCVerified)
      points += manageReferralsData?.data?.profile_kyc_update;
    if (!user.country)
      points += manageReferralsData?.data?.profile_country_update;
    if (!user.whatsapp)
      points += manageReferralsData?.data?.profile_whatsapp_update;

    return points;
  };

  const getContainerWidth = () => {
    if (data?.data[0]?.is_premium_plus) return "280px";
    if (data?.data[0]?.is_premium) return "280px";
    return "242px";
  };

  return (
    <div
      className="headerDropdown-container"
      style={{
        top: visible ? "55px" : "-800px",
        width: getContainerWidth(),
      }}
    >
      <div className="headerDropdown-inner-container">
        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/my-profile");
            setVisible(false);
          }}
        >
          <span>
            <FiUser /> My Profile
          </span>
          {calculateAvailablePoints() > 0 && (
            <div className="headerDropdown-points-badge">
              <span>Get {calculateAvailablePoints()}</span>
              <img
                src={pointsIcon}
                alt="points"
                className="headerDropdown-points-icon"
              />
            </div>
          )}
          <span
            className="membership-badge"
            style={{
              backgroundColor: data?.data[0]?.is_premium_plus
                ? "#e0aa3e"
                : data?.data[0]?.is_premium
                ? "#4CAF50"
                : "#2f68fd",
              padding: "3px 5px !important",
              borderRadius: "5px !important",
              fontSize: data?.data[0]?.is_premium_plus ? 11 : 12,
              color: "#fff",
            }}
          >
            {data?.data[0]?.is_premium_plus
              ? "Premium Plus"
              : data?.data[0]?.is_premium
              ? "Premium"
              : "Free"}
          </span>
        </button>

        {!data?.data[0]?.is_premium && !data?.data[0]?.is_premium_plus && (
          <button
            className="headerDropdown-item"
            onClick={() => {
              navigate("/home/premium");
              setVisible(false);
            }}
          >
            <span>
              <IoRocketOutline /> Activate Premium
            </span>
          </button>
        )}
        {!data?.data[0]?.is_premium_plus && (
          <button
            className="headerDropdown-item"
            onClick={() => {
              navigate("/home/premium");
              setVisible(false);
            }}
          >
            <span>
              <IoRocketOutline /> Activate Premium Plus
            </span>
          </button>
        )}

        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/help-and-support");
            setVisible(false);
          }}
        >
          <span>
            <TfiHeadphoneAlt /> Help and Support
          </span>
        </button>

        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/about");
            setVisible(false);
          }}
        >
          <span>
            <SlInfo /> About
          </span>
        </button>

        <button className="headerDropdown-item" onClick={handleLogout}>
          <span>
            <FiLogOut /> Sign Out
          </span>
        </button>
      </div>
    </div>
  );
};

export default HeaderDropDown;
