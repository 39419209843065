import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  country: null,
  // Add other profile fields if needed
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.country = action.payload.country;
      // Set other fields if needed
    },
  },
});

export const { setUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
